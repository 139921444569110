<template>
  <TaskDetailItemEditCard confirmation-message="Status van de taak werd succesvol gewijzigd" title="Bewerk status van taak"
    update-store-action="tasksModule/updateTaskStatus"
    :update-store-action-payload="{ taskStatusDTO: taskStatusDTO, projectId: projectId, clientId: clientId, taskId: taskId }"
    :projectId="projectId" :clientId="clientId" :taskId="taskId" :promises.sync="promises">
    <template v-slot:form-fields>
      <v-row>
        <v-col cols="12" md="6">
          <v-autocomplete :items="getTaskStatusOptions" type="text" v-model="taskStatusDTO.taskStatus" persistent-placeholder label="Status" />
        </v-col>
      </v-row>
    </template>
  </TaskDetailItemEditCard>
</template>

<script>
import TaskDetailItemEditCard from "@/views/planning/forms/TaskDetailItemEditCard.vue"
import { taskStatuses } from '@/shared/constants/TaskStatuses'
import { translateTaskStatus } from "../../../shared/utils/translateUtils"

export default {
  name: "TaskDetailEditStatus",
  props: {
    projectId: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    },
    taskId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      taskStatuses,
      taskStatusDTO: {
        taskStatus: null
      },
      promises: [
        // fetch current status
        this.$store.dispatch('tasksModule/fetchTaskStatus', 
          { 
            clientId: this.clientId,
            projectId: this.projectId,
            taskId: this.taskId,
            taskStatusDTO: { taskStatus: this.taskStatus }
          })
          .then((data) => this.taskStatusDTO.taskStatus = data.taskStatus)
      ]
    }
  },
  methods: {
    updateStatus() {
      this.$store.dispatch('tasksModule/updateTaskStatus', 
        { 
          clientId: this.clientId,
          projectId: this.projectId,
          taskId: this.taskId,
          taskStatusDTO: { taskStatus: this.taskStatus }
        })
        .then((taskStatus) => this.taskStatus = taskStatus)
    },
    translateTaskStatus
  },
  components: {
    TaskDetailItemEditCard
  },
  computed: {
    getTaskStatusOptions() {
      // get all task statuses and map them for the v-autocomplete
      return Object.keys(this.taskStatuses).map((taskStatus) => ({ value: taskStatus, text: translateTaskStatus(taskStatus) }))
    }
  }
}
</script>